<template>
  <section id="teaser">
    <div class="landing-video left-column">
      <video
        ref="videoRef"
        src="LandingVideo"
        class="tw-rounded-3xl landing-video-shadow"
        muted
        loop
        playsinline
      ></video>
    </div>
    <div class="right-column">
      <header class="main-heading">
        <div class="line">
          <span style="color: #d62a53">Explore & Book</span>
        </div>
        <div class="second-heading">
          trips from your favorite <br />
          experiences!
        </div>
      </header>
      <div class="interests-selection">


              <v-card class="interests-selector rounded-pill">
                  <div class="form-label">Where are you traveling from?</div>
                  <v-autocomplete
                    class="drop"
                    :loading="loading"
                    hide-details="auto"
                    v-model="selectedCity"
                    :items="cities"
                    flat
                    solo
                    auto
                  >
                    <template v-slot:append> <LightArrow class="tw-mt-2"/> </template>
                  </v-autocomplete>
              </v-card>
   
          

          <v-btn
            class="button tw-ml-5"
            color="primary"
            @click="handleLetsGo"
            :disabled="!valid"
          >
            <BalloonIcon class="buttonLabel"></BalloonIcon> Let's Go</v-btn
          >
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BalloonIcon from '@/assets/landingPage/balloon.svg';
import axios from 'axios';
import cities from '@/assets/cities.json';
import LightArrow from '@/assets/landingPage/lightarrow.svg';

export default {
  name: 'landingPageSection1New',
  components: { BalloonIcon, LightArrow },
  data() {
    return {
      index: 0,
      interestForm: [],
      items: [],
      loading: false,
      selectedCity: '',
      interestRules: [ v => !!v.length || '*Required' ],
      fullWidth: true,
      cities: cities.map(city => ({ text: city, value: city })),
      cityPath: null,
    };
  },
  watch: {
    selectedExperience(newValue) {
      this.index = this.experiences.findIndex(it => it.name === newValue);
    },
  },
  async created() {
    await axios.get('https://geolocation-db.com/json/').then(response => {
      this.selectedCity = response?.data?.city ?? '';
    });
  },
  mounted() {
    this.$refs.videoRef.src = require('@/assets/landingPage/balloon_landing.mp4');
    this.$refs.videoRef.play();
  },
  computed: {
    ...mapGetters([ 'experiences' ]),
    currentCity: function() {
      return this.experiences[this.index];
    },
    experienceSelectItems() {
      return this.experiences.reduce(
        (acc, item) => [ ...acc, { text: item.name, value: item.name } ],
        []
      );
    },
    selectedInterests() {
      return this.interests
        .map((interest, index) => index)
        .filter(item => this.interestForm.includes(this.interests[item]))
        .map(item => item + 1)
        .join('-');
    },
    valid() {
      return this.selectedCity;
    },
  },
  methods: {
    ...mapActions([ 'loadExperiences' ]),
    handleChange(selection) {
      const experience = this.experiences.find(it => it.name === selection)
        .experiences[0];
      this.$store.commit('setExperience', experience);
      this.$router.push('/destination/' + selection);
    },
    handleLetsGo() {
      if (!this.valid) return;
      this.$router.push({
        name: 'DestinationFeed',
        query: { ...this.$route.query, city: this.selectedCity },
      });
    },
    setInterest(val, index) {
      this.interestForm.includes(this.interests[index]).valueOf = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/grid.scss";

#teaser {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 102px 0;
  margin-left: 10%;
  //margin: auto;
}

.left-column {
  flex: 0 0 33%;
}
.right-column {
  flex: 0 0 59.5%;
  margin-left: 24px;
}
.landing-video {
  border-radius: 30px;
  position: relative;
}

.landing-video-shadow {
  filter: drop-shadow(0px 30px 30px gray);
}

.video {
  width: 100%;
  height: auto;
  aspect-ratio: 9/16;
}

.main-heading {
  display: block;
  margin-top: 25.75%;
  line-height: 1;
  color: black;
  font-weight: 400;
  font-family: "PoppinsSemiBold";
  font-size: min(5.2vw, 90px);
  letter-spacing: -0.04em;
  .second-heading {
    font-size: min(3.2vw, 65px);
  }
}

.interests-selection {
  //position: absolute;
  //background-color: white;
  bottom: 24%;
  width: 34vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 8%;
  max-height: 5.5rem;
  height: 5.4vw;
  align-items: center;
  border-radius: 999px;
}
.interests-selector {
  height: 5.4vw;
  padding: 1rem 2.625rem;
  overflow: hidden;
  width: 24vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-color: $secondary;
}
.interests-selector > .form-label {
  font-size: 0.8vw;
  color: #747378;
  text-transform: uppercase;
  font-family: "MontserratSemiBold";
  line-height: 0.75rem;
  margin-top: 1.25vw;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .interests-selector > .form-label {
      font-size: 0.75vw;
      color: #747378;
      text-transform: uppercase;
      font-family: "MontserratSemiBold";
      line-height: 0.75rem;
      margin-top: 0.75vw;
    }
    .interests-selector::v-deep .v-select__selection {
      font-size: 0.8rem;
      color: black;
      font-family: "MontserratBold";
    }

    .v-input {
      font-size: 0.8vw !important;
    }
  }
}

.interests-selector::v-deep .v-input__slot {
  min-height: 2em !important;
  padding: 0 !important;
}
.interests-selector::v-deep .v-input__slot input {
  padding: 0;
  font-family: "MontserratBold";
}
.interests-selector::v-deep .v-input__append-inner {
  margin-top: -1em;
  cursor: pointer;
}

.interests-selector::v-deep .v-select__selection {
  font-size: 0.5rem;
  color: black;
  font-family: "MontserratBold";
}
.interests-selection > button.v-btn {
  border-radius: 999px;
  height: 4.5vw;
  font-size: 1.1rem;
  margin-right: 0.52vw;
  font-family: MontserratBold;
  letter-spacing: -0.02em;
}

.button {
  min-width: 30% !important;
}

.buttonLabel {
  margin-right: 0.25rem;
}

// .v-btn--is-elevated {
//   box-shadow: unset !important;
// }

.v-sheet.v-card {
  box-shadow: unset !important;
}
</style>
