<template>
  <div class="">
    <div class="main-content-container hidden-xs-only">
      <TeaserSection v-if="!isMobile()"/>
      <CreatePackages v-if="!isMobile()"/>
      <NewToTheSite v-if="!isMobile()"/>
      <PartnershipSection v-if="!isMobile()"/>
    </div>
    <!-- <div class="d-flex d-sm-none">
      <MobileLandingPage/>
    </div> -->
    </div>
</template>

<script>
import TeaserSection from './sections/teaser.vue';
import CreatePackages from '@/views/landingPage/sections/CreatePackages';
import PartnershipSection from '@/views/landingPage/sections/partnership.vue';
import NewToTheSite from '@/views/landingPage/sections/NewToTheSite.vue';
//import MobileLandingPage from '@/views/mobilePages/Landingpage/MobileLandingPage.vue';

export default {
  name: 'landingPage',
  components: {
    TeaserSection,
    CreatePackages,
    NewToTheSite,
    PartnershipSection,
    //MobileLandingPage,
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/app.scss';

.main-content-container {
  width: 85%;
  //max-width: $screen-lg;
  margin: auto;
  margin-left: 4.75rem;
  overflow-x: hidden;
  // @include smaller-than(lg) { max-width: $screen-md; }
  // @include smaller-than(md) { max-width: $screen-sm; }
  // @include smaller-than(sm) { max-width: $screen-xs; }
}
</style>
