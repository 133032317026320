<template>
  <div class="tw-p-3">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      class="calendar"
    >
      <template v-slot:activator="{ on, attrs }">
        <svg
          class="tw-cursor-pointer "
          @click="
            () => {
              menu = true;
            }
          "
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.332 9.77209V13.3321C13.332 13.6857 13.1916 14.0248 12.9415 14.2749C12.6915 14.5249 12.3523 14.6654 11.9987 14.6654H2.66536C2.31174 14.6654 1.9726 14.5249 1.72256 14.2749C1.47251 14.0248 1.33203 13.6857 1.33203 13.3321V3.99876C1.33203 3.64513 1.47251 3.306 1.72256 3.05595C1.9726 2.8059 2.31174 2.66542 2.66536 2.66542H6.22537M11.9987 1.33203L14.6654 3.9987L7.9987 10.6654H5.33203V7.9987L11.9987 1.33203Z"
            stroke="#D62A53"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <v-text-field
          class="tw-hidden text-field tw-text-lg tw-font-montserrat-medium tw-text-title"
          :label="label"
          :value="formatValue"
          readonly
          dense
          filled
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          ><template v-slot:label>
            <div
              class="tw-text-xs tw-font-montserrat-semi-bold tw-text-paragraph tw-text-paragraph tw-text-left tw-text-black title-text"
            ></div> </template
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :min="min"
        @input="menu = false"
        no-title
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { dateForAvailabilityCalendar } from '@/utils/appDate.js';
export default {
  props: {
    label: String,
    value: String,
    min: String,
    rules: Array
  },
  data: () => ({
    menu: false
  }),
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
        console.log('inpu');
      }
    },
    formatValue: function() {
      return dateForAvailabilityCalendar(new Date(this.value));
    }
  }
};
</script>

<style scoped>
/* .v-picker {
  width: 380px;
} */

.text-field {
  padding: 0;
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #28262e;
}
.text-field::v-deep .v-input__slot {
  border: none !important;
  padding: 7px 22px !important;
  border-radius: 5px;
  background: transparent !important;
}
.text-field::v-deep .v-input__slot:before {
  border: none !important;
}
.text-field::v-deep .v-input__slot:after {
  border: none !important;
}
.text-field::v-deep input {
  padding: 0 !important;
  font-size: 13px;
}

.text-field::v-deep .v-label {
  color: black;
}
.title-text {
  font-size: 15px;
}
</style>
