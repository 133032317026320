<template>
  <div>
    <div class="title-holder left-column tw-mt-20 container mx-auto">
      <div class="sub-header">Can't decide what to book?</div>
      <div class="main-header">
        <div>Buy A Curated</div>
        <div>Package <span style="color: #d62a53">Quickly</span></div>
      </div>
    </div>
    <div class="tw-container mx-auto">
      <div
        class="
          tw-flex
          tw-items-center
          tw-justify-between
          tw-mx-auto
          tw-font-poppins
          tw-mb-32
          tw-ml-2
          tw-mt-20
        "
      >
        <div class="chevron chevron-left" @click="handleClickLeft">
          <v-icon>mdi-chevron-left</v-icon>
        </div>
        <div class="left-column">
          <div class="tw-text-center titles-padding">
            <span class="title title-padding">
              <template v-if="experience">
                {{ selectedExperience.package_name }}
              </template>
              <template v-else>
                <div class="skeleton" />
              </template>
            </span>
            <div class="subtext">
              <template v-if="experience">
                {{ selectedExperience.package_subtext }}
              </template>
              <template v-else>
                <div class="skeleton" />
              </template>
            </div>
          </div>
          <div class="offers-container titles-padding">
            <OfferLine type="flight">
              <template v-if="!loadingFlight">
                <flights-item
                  v-if="typeof flight === 'object'"
                  class="tw-border-0"
                  :key="`flight`"
                  ref="flightItem"
                  @setDeparture="setDeparture"
                  @setArrival="setArrival"
                  :departure_at="flight.departure_at"
                  :arrival_at="flight.arrival_at"
                  :city_from="flight.city_from"
                  :airport_from="flight.airport_from"
                  :airport_from_code="flight.airport_from_code"
                  :city_to="flight.city_to"
                  :city_code_to="flight.city_code_to"
                  :airport_to="flight.airport_to"
                  :airport_to_code="flight.airport_to_code"
                  :itinerary="flight.itinerary"
                  :duration="flight.duration"
                  :availability="flight.availability"
                  :nights_in_dest="flight.nights_in_dest"
                  :price="flight.price"
                />
                <!-- v-bind="flight" -->
              </template>

              <FlightsItemSkeleton class="tw-border-0" :inline="true" v-else />
            </OfferLine>
            <OfferLine
              v-if="showDisclaimer"
              type="accommodation"
              :item="{
                name: selectedExperience.hotels[0].name,
                description: '',
                image: selectedExperience.hotels[0].image,
                price: selectedExperience.hotels[0].price,
                bubbles: [
                  { text: '2 Guests' },
                  {
                    text: formatDates(
                      flight.arrival_at,
                      this.hotelCheckOutDate
                    ),
                  },
                ],
              }"
            />
            <OfferLine
              v-else-if="hotelOffer"
              type="accommodation"
              :item="hotelOffer"
            />

            <HotelSkeleton v-else type="accommodation" />
            <OfferLine
              :location="location"
              type="activity"
              :item="activityOffer"
            />
            <OfferLine
              :location="location"
              type="restaurant"
              :item="restaurantOffer"
            />

            <div
              class="price-container tw-flex tw-flex-center tw-justify-between"
            >
              <div class="tw-flex tw-flex-start">
                <span class="disclaimer tw-mr-auto">
                  <div>Disclaimer:</div>
                  <div>Prices are subject to change with dates.</div>
                </span>
              </div>
              <div class="total-holder">
                <span class="apprx">Apprx.</span>
                <span class="total">Total</span>
                <span v-if="flight" class="price"
                  >€{{ totalPrice.toFixed(2) }}</span
                >
              </div>
            </div>
          </div>

          <div class="tw-my-10 tw-flex tw-justify-center">
            <v-btn
              class="button cta"
              color="primary"
              @click="handleClickCustomize"
            >
              <SearchIcon class="tw-w-4 tw-h-4 tw-mr-2" />
              Customize trip!
            </v-btn>
          </div>
        </div>

        <div class="right-column">
          <div class="tw-flex tw-flex-col storycontainer">
            <ExpandStoryCarousel
              @toggle-expand="
                () => {
                  expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
                }
              "
              @next-story-slide="
                () => {
                  story().next();
                }
              "
              @prev-story-slide="
                () => {
                  story().prev();
                }
              "
              :enabled="expandStoryCarouselEnabled"
            >
              <div class="">
                <InstaStory
                  :style="[
                    !expandStoryCarouselEnabled
                      ? { filter: 'drop-shadow(0px 30px 30px gray)' }
                      : '',
                  ]"
                  :showButtons="!expandStoryCarouselEnabled"
                  :items="storyItems"
                  :storyId="selectedExperience.exp_id"
                  :influencer="selectedExperience.influencer_data"
                  ref="story"
                  class="story"
                  :key="storyKey"
                  :active="true"
                  :expandStoryCarouselEnabled="expandStoryCarouselEnabled"
                  @toggle-expand="
                    () => {
                      expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
                    }
                  "
                />
              </div>
            </ExpandStoryCarousel>
          </div>
        </div>
        <div class="chevron chevron-right" @click="handleClickRight">
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import FlightsItemSkeleton from '@/views/landingPage/sections/CreatePackages/FlightsItemSkeleton.vue';
import FlightsItem from '@/views/shoppingCart/tabs/flights/FlightsItem2';
import InstaStory from '@/views/inspiration/insta-story/InstaStory.vue';
import OfferLine from '@/views/landingPage/sections/OfferLine';
import HotelSkeleton from '@/views/landingPage/sections/CreatePackages/HotelSkeleton.vue';
import SearchIcon from '@/assets/inspirationPage/cart.svg';
import ExpandStoryCarousel from '@/views/inspiration/ExpandStoryCarousel.vue';

const experiences = [ 'experience2' ];

export default {
  name: 'CreatePackages',

  data() {
    return {
      flight: null,
      storyKey: 0,
      index: 0,
      selectedExperience: null,
      cities: {},
      showDisclaimer: false,
      selectedCity: this.$route.query.city,
      hotelCheckOutDate: null,
      expandStoryCarouselEnabled: false,
      checkInDate: moment().add(30, 'days'),
      checkOutDate: moment().add(33, 'days'),
      durationInDays: 3,
    };
  },
  components: {
    OfferLine,
    FlightsItem,
    FlightsItemSkeleton,
    InstaStory,
    ExpandStoryCarousel,
    HotelSkeleton,
    SearchIcon,
  },
  async mounted() {
    (async() =>
      (this.cities = await axios.get(
        'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
      )))();

    !this.PackageExperiences.length && (await this.loadPackageExperiences());
    this.selectedExperience = this.PackageExperiences[0];
    Promise.all([
      this.obtainLocation(),
      this.experience
        ? Promise.resolve(this.experience)
        : this.$store.dispatch('loadExperience', experiences[0]),
    ]).then(() => {
      this.loadFlightsAndHotels();
    });
  },
  computed: {
    ...mapGetters([
      'experience',
      'cityaccommodation',
      'availabilityHotels',
      'country',
      'PackageExperiences',
    ]),
    loadingFlight: {
      get() {
        return typeof this.flight !== 'object';
      },
    },
    storyRef: {
      get() {
        return this.$refs.story;
      },
    },
    experiences: {
      get() {
        return this.$store.state.experiences.experiences;
      },
    },
    location: {
      get() {
        try {
          const city = this.cities.data[this.selectedExperience.city_id];
          return city.name + ', ' + city.country;
        } catch (err) {
          return '';
        }
      },
    },

    hotel() {
      return this.availabilityHotels?.[0];
    },

    hotelOffer() {
      if (!this.hotel) {
        return undefined;
      }

      const splitRateKey = this.hotel.rateKey.split('|');
      const dateFrom = moment(splitRateKey[0], 'YYYYMMDD').format(
        'DD MMM YYYY'
      );
      const dateTo = moment(splitRateKey[1], 'YYYYMMDD').format('DD MMM YYYY');

      return {
        name: this.hotel.name,
        description: this.hotel.roomName,
        image: this.hotel.image.path,
        price: parseFloat(
          this.hotel.sellingRate ? this.hotel.sellingRate : this.hotel.net
        ),
        bubbles: [ { text: '2 Guests' }, { text: dateFrom + ' - ' + dateTo } ],
      };
    },

    activityOffer: {
      get() {
        if (!this.activity) {
          return undefined;
        }

        return {
          name: this.activity.name,
          image: this.activity.image,
          price: this.activity.price,
          bubbles: [ { text: '2 Guests' } ],
        };
      },
    },
    restaurantOffer() {
      if (!this.restaurant) {
        return undefined;
      }

      return {
        name: this.restaurant.name,
        image: this.restaurant.image,
        price: '€',
        bubbles: [ { text: '2 Guests' } ],
      };
    },
    activity() {
      return this.selectedExperience?.activities?.[0];
    },
    flightPrice() {
      return this.flight?.price?.[0]?.price;
    },
    totalPrice() {
      return (
        0 +
        (this.showDisclaimer ? this.selectedExperience.hotels[0].price : 0) +
        // (this.restaurant?.price || 0) +
        (this.flightPrice || 0) +
        (this.activity?.price || 0) +
        parseFloat(
          (this.hotel?.sellingRate
            ? this.hotel?.sellingRate
            : this.hotel?.net) || 0
        )
      );
    },
    restaurant() {
      return this.selectedExperience?.restaurants?.[0];
    },
    storyItems() {
      return this.selectedExperience.story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image',
        };
      });
    },
  },
  methods: {
    ...mapActions([
      'loadAvailabilityHotels',
      'loadExperiences',
      'loadPackageExperiences',
    ]),
    story() {
      return {
        next: () => this.$refs.story.$refs.carousel.nextSlide(),
        prev: () => this.$refs.story.$refs.carousel.prevSlide(),
      };
    },
    setDeparture(d) {
      if (moment(d, 'YYYY-MM-DD') <= this.checkOutDate) {
        this.checkInDate = moment(d, 'YYYY-MM-DD');
        this.flight = -1;
        this.loadFlightsAndHotels();
      } else {
        alert('Check in should be before check out');
      }
    },
    setArrival(d) {
      if (this.checkInDate <= moment(d, 'YYYY-MM-DD')) {
        this.checkOutDate = moment(d, 'YYYY-MM-DD');
        this.flight = -1;
        this.loadFlightsAndHotels();
      } else {
        alert('Check in should be before check out');
      }
    },
    async loadFlightsAndHotels() {
      if (this.checkInDate <= this.checkOutDate) {
        await this.loadFlights();
        this.loadHotel();
      } else {
        alert('Check in should be before check out');
      }
    },
    ...mapActions('location', [ 'obtainLocation' ]),
    formatDates: function(a, b) {
      return (
        moment(a).format('DD MMM YYYY') +
        ' - ' +
        moment(b).format('DD MMM YYYY')
      );
    },
    format: function(date, format) {
      return moment(date).format(format);
    },
    async loadFlights() {
      this.flight = -1;

      const airportCodes = this.$store.getters['location/airports']?.map(
        airport => airport.code
      ) || [ 'BER' ];
      for (let index = 0; index < airportCodes.length; index++) {
        const departureAirport = airportCodes[index];
        const destinationAirport = this.selectedExperience.flight_destination;

        const response = await axios.get(
          // 'https://flights.dev.go-travelo.com/api/search',
          'https://api.tequila.kiwi.com/v2/search',
          {
            headers: {
              apikey: 'ZrsW4-6pv0JOWcXtplHvWs81EoU_HEJ5',
            },
            params: {
              fly_from: departureAirport,
              fly_to: destinationAirport,
              // departure_date: this.checkInDate.format('YYYY-MM-DD'),
              date_from: this.checkInDate.format('YYYY-MM-DD'),
              // return_date: this.checkOutDate.format('YYYY-MM-DD'),
              date_to: this.checkOutDate.format('YYYY-MM-DD'),
              max_stopovers: 10,
              max_fly_duration: 50,
              // limit: 1,
            }
          }
        );

        if (response.data.length > 0) {
          this.flight = response.data[0];
          break;
        }
      }
    },
    loadHotel: async function() {
      if (!this.flight) {
        this.showDisclaimer = true;
        return;
      } else {
        this.showDisclaimer = false;
      }

      console.log(this.$refs.flightItem);
      let dateOfCheckIn = moment(this.flight.arrival_at).subtract(1, 'day');
      let dateOfCheckOut = moment(
        this.$refs.flightItem.returnRoute?.departure_at
      ).subtract(1, 'day');
      this.hotelCheckOutDate = dateOfCheckOut;
      this.$store.commit('setIsNoContent', false);

      const findHotel = async(checkIn, checkOut, hotelbeds_code) => {
        let nights = checkOut.diff(checkIn, 'days');
        checkIn = checkIn.startOf('day').format('YYYY-MM-DD');
        checkOut = checkOut.startOf('day').add(1, 'day').format('YYYY-MM-DD');
        return await this.loadAvailabilityHotels({
          hotels: {
            hotel: [ parseInt(hotelbeds_code) ],
          },
          checkInDate: checkIn,
          nightsCount: nights,
          checkOutDate: checkOut,
          roomsCount: 1,
          adultsCount: 2,
          childrenCount: 0,
          maxHotels: 1,
        });
      };

      if (
        (await findHotel(
          dateOfCheckIn,
          dateOfCheckOut,
          this.selectedExperience.hotelbeds_code
        )) < 1
      ) {
        this.showDisclaimer = true;
      } else {
        this.showDisclaimer = false;
      }
      // for (let i = 0; i <= 4; i += 1) {
      //   try {
      //     let result = await findHotel(
      //       dateOfCheckIn.add(i * 30, 'day'),
      //       dateOfCheckOut.add(i * 30, 'day'),
      //       this.selectedExperience.hotelbeds_code
      //     );
      //     console.log({ i: result });
      //     if (result > 0) {
      //       break;
      //     }
      //     if (i === 12) {
      //       console.log('no availability');
      //     }
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    },
    async handleClickLeft() {
      this.showDisclaimer = false;
      this.$store.commit('setAvailabilityHotels', []);

      let newIndex = this.index - 1;
      if (newIndex < 0) {
        newIndex = this.PackageExperiences.length - 1;
      }
      this.selectedExperience = this.PackageExperiences[newIndex];
      this.$refs.story.resetSlide();
      this.storyKey += 1;
      this.index = newIndex;

      this.loadFlightsAndHotels();
    },
    async handleClickRight() {
      this.showDisclaimer = false;
      this.$store.commit('setAvailabilityHotels', []);

      let newIndex = this.index + 1;
      if (newIndex >= this.PackageExperiences.length) {
        newIndex = 0;
      }
      this.selectedExperience = this.PackageExperiences[newIndex];
      this.$refs.story.resetSlide();
      this.storyKey += 1;
      this.index = newIndex;

      this.loadFlightsAndHotels();
    },
    handleClickCustomize() {
      this.$store.commit('updateHotelPackage', this.hotel);
      this.$store.commit('setExperience', this.selectedExperience);
      this.$router.push({
        path: '/express-checkout/' + this.selectedExperience.exp_id,
      });
    },
  },
  watch: {
    PackageExperiences() {
      this.selectedExperience = this.PackageExperiences[this.index];
    },
    expandStoryCarouselEnabled: function() {
      if (this.expandStoryCarouselEnabled) {
        document.querySelector('html')?.classList.add('hotel-modal-open');
      } else {
        document.querySelector('html')?.classList.remove('hotel-modal-open');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/app.scss';
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/skeleton.scss';

.chevron {
  flex: 0 0 5.3%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.chevron > i {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background: #f0f0f1;
  border-radius: 1.5rem;
}

.mdi-chevron-left {
  color: black;
}
.mdi-chevron-right {
  color: black;
}

.title {
  font-family: 'MontserratRegular' !important;
  font-size: min(3.6vw, 58px) !important;
  line-height: 4vw;
  vertical-align: top;
  letter-spacing: -0.04em;

  //margin-top: 2rem !important;

  // color: #000000;
  // @include respond-to(lg) {
  //   font-size: 2rem !important;
  // }

  // @include respond-to(md) {
  //   font-size: 1.5rem !important;
  // }

  // @include respond-to(sm) {
  //   font-size: 1rem !important;
  // }
}

.titles-padding {
  .title-padding {
    margin-left: 2%;
  }
}

.title-holder {
  margin-left: 4% !important;
}

.subtext {
  font-weight: 200;
  font-family: 'MontserratRegular' !important;
  font-size: 1.953vw !important;
  line-height: 1;
  vertical-align: top;
  letter-spacing: -4%;
  margin-top: 0.45rem;
}

.sub-header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: min(3.2vw, 45px);
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #747378;
}

.main-header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: min(5.2vw, 90px);
  line-height: 94%;
  color: #28262e;
  margin-top: 0.75rem;
}

.story {
  margin: 30px;
  border-radius: 30px;
  //filter: drop-shadow(0px 30px 30px gray);
}

.right-column {
  flex: 0 0 44%;
  margin-left: 4%;
}
// .left-column {
//   flex: 0 0 51.5%;
// }

.cta {
  border-radius: 2rem !important;
  min-width: 30% !important;
  font-family: 'MontserratBold';
  font-size: 1.125rem;
  box-shadow: none !important;
}

.total {
  font-family: PoppinsMedium;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #28262e;
}

.price {
  font-family: Poppins;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #747378;
  margin-left: 16px;
}

.apprx {
  font-family: PoppinsMedium;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #747378;
  margin-right: 4px;
}

.price-container {
  padding: 32px;
}

.offers-container {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: 30px;
  margin-top: 35px;
}

.disclaimer {
  div:first-child {
    color: #d62a53;
    height: 100%;
    margin-right: 4px;
  }
  display: flex;
  font-family: Poppins;
  font-size: 0.75vw;
  line-height: 0.75vw;
  letter-spacing: 0.02em;
  color: #747378;
  max-width: 400px;
}

@media only screen and (max-width: 2560px) {
  .right-column {
    flex: 0 0 44.5%;
  }
}

@media only screen and (min-width: 1441px) {
  .left-column {
    flex: 0 0 48.5%;
  }
  .right-column {
    flex: 0 0 46.5%;
  }
}

@media (min-width: 1281px) {
  .right-column {
    flex: 0 0 39.5%;
  }
}
</style>
