<template>
  <div class="tw-flex tw-items-center tw-border-b tw-p-4">
    <div
      v-bind:style="
        type === 'flight' ? { marginTop: '16px', marginBottom: 'auto' } : {}
      "
      class="icon-container tw-flex tw-rounded-full tw-items-center tw-justify-center"
    >
      <PlaneIcon v-if="type === 'flight'" />
      <AccommodationIcon v-if="type === 'accommodation'" />
      <ActivitiesIcon v-if="type === 'activity'" />
      <RestaurantIcon v-if="type === 'restaurant'" />
    </div>

    <div class="tw-ml-6 tw-flex tw-items-center tw-w-full">
      <slot>
        <div
          class="tw-w-20 tw-h-20 tw-bg-cover tw-rounded-lg"
          :style="{ backgroundImage: 'url(' + item.image + ')' }"
        />

        <div class="tw-flex tw-flex-col tw-ml-4 tw-flex-grow">
          <span v-if="location" class="location tw-flex tw-items-center">
            <PinIcon class="tw-w-4" />
            <span class="tw-ml-1"
              >{{location}}
            </span>
          </span>

          <span class="name tw-mt-1 tw-text-lg">
            {{ item.name }}
          </span>

          <span v-if="item.description" class="description tw-mt-1 tw-text-lg">
            {{ item.description }}
          </span>

          <div
            class="tw-mt-1 tw-fot-montserrat-medium tw-flex tw-flex-grow tw-justify-between"
          >
            <div class="tw-flex">
              <div
                v-for="bubble in item.bubbles"
                :key="bubble.text"
                class="chip"
              >
                {{ bubble.text }}
              </div>
            </div>

            <div class="price" v-if="item.price">
              <span>Price</span> €{{ item.price }}
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import PinIcon from '@/assets/createPackages/pin.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import PlaneIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'OfferLine',
  components: {
    PinIcon,
    PlaneIcon,
    AccommodationIcon,
    ActivitiesIcon,
    RestaurantIcon
  },
  computed: {
    ...mapGetters([ 'cityaccommodation', 'country' ])
  },
  props: {
    item: Object,
    type: String,
    location: String
  }
};
</script>

<style lang="scss" scoped>
.chip {
  padding: 5px 12px;
  background: #ffe4ec;
  border-radius: 90px;

  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #d62a53;

  margin-right: 8px;
}

.chip:not(:first-child) {
  background-color: #f1f1f2;
  color: #747378;
}

.icon-container {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: #ffe4ec;
}

.price {
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  color: #28262e;

  span {
    font-family: MontserratMedium;
    font-size: 12px;
    line-height: 12px;
    color: #747378;
  }
}

.location {
  font-family: MontserratSemiBold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #747378;
}
.name {
  font-family: PoppinsMedium;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #28262e;
}

.description {
  font-family: MontserratSemiBold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #28262e;
}
</style>
