<template>
  <div
    class="cart-flight-item tw-flex tw-flex-col tw-py-5"
  >
    <div class="tw-w-full">
        <div class="tw-text-left flight-column chip-grey tw-mt-16">
            Getting the best flight prices for you from...
        </div>
        <div class="tw-flex tw-flex-row tw-justify-center tw-mt-2">
            <KiwiIcon class="tw-mr-4"/>
            <KayakIcon class="tw-mt-4"/>
        </div>
    </div>
  </div>
</template>

<script>
import KayakIcon from '@/assets/landingPage/kayak.svg';
import KiwiIcon from '@/assets/inspirationPage/tabicons/kiwi.svg';
export default {
  components: {
    KayakIcon,
    KiwiIcon,
  },
  data: () => ({
  }),
};
</script>
<style scoped lang="scss">
.cart-flight-item {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  border-radius: 1rem;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  background: #ffffff;
  border-radius: 16px;
  padding-left: 0rem;
  margin-top: 2.75rem;
  margin-bottom: 2rem;
}

.chip-grey {
  color: 787373;
  margin-left: 12px !important;
  font-size: 0.9rem;
  color: #787373;
}

.chip-primary {
  background: #ffe4ec;
  color: #ffe4ec;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  //height: 24px;
  padding: 0 12px;
  margin-left: 0;
  margin-top: 2px;
}

.flight-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flight-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
}

.price {
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  color: #f1f1f1;
  background: #f1f1f1;
  span {
    font-family: MontserratMedium;
    margin-right: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #f1f1f1;
    background: #f1f1f1;
  }
}
.experience-type-icon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: #7473781a;
  margin-right: 16px;
  display: flex;
  margin-top: 11px;

  svg {
    margin: auto;
  }
}
* {
  user-select: none;
}
</style>
