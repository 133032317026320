var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"tw-container mx-auto"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-mx-auto tw-font-poppins tw-mb-32 tw-ml-2 tw-mt-20"},[_c('div',{staticClass:"chevron chevron-left",on:{"click":_vm.handleClickLeft}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"left-column"},[_c('div',{staticClass:"tw-text-center titles-padding"},[_c('span',{staticClass:"title title-padding"},[(_vm.experience)?[_vm._v(" "+_vm._s(_vm.selectedExperience.package_name)+" ")]:[_c('div',{staticClass:"skeleton"})]],2),_c('div',{staticClass:"subtext"},[(_vm.experience)?[_vm._v(" "+_vm._s(_vm.selectedExperience.package_subtext)+" ")]:[_c('div',{staticClass:"skeleton"})]],2)]),_c('div',{staticClass:"offers-container titles-padding"},[_c('OfferLine',{attrs:{"type":"flight"}},[(!_vm.loadingFlight)?[(typeof _vm.flight === 'object')?_c('flights-item',{key:`flight`,ref:"flightItem",staticClass:"tw-border-0",attrs:{"departure_at":_vm.flight.departure_at,"arrival_at":_vm.flight.arrival_at,"city_from":_vm.flight.city_from,"airport_from":_vm.flight.airport_from,"airport_from_code":_vm.flight.airport_from_code,"city_to":_vm.flight.city_to,"city_code_to":_vm.flight.city_code_to,"airport_to":_vm.flight.airport_to,"airport_to_code":_vm.flight.airport_to_code,"itinerary":_vm.flight.itinerary,"duration":_vm.flight.duration,"availability":_vm.flight.availability,"nights_in_dest":_vm.flight.nights_in_dest,"price":_vm.flight.price},on:{"setDeparture":_vm.setDeparture,"setArrival":_vm.setArrival}}):_vm._e()]:_c('FlightsItemSkeleton',{staticClass:"tw-border-0",attrs:{"inline":true}})],2),(_vm.showDisclaimer)?_c('OfferLine',{attrs:{"type":"accommodation","item":{
              name: _vm.selectedExperience.hotels[0].name,
              description: '',
              image: _vm.selectedExperience.hotels[0].image,
              price: _vm.selectedExperience.hotels[0].price,
              bubbles: [
                { text: '2 Guests' },
                {
                  text: _vm.formatDates(
                    _vm.flight.arrival_at,
                    this.hotelCheckOutDate
                  ),
                },
              ],
            }}}):(_vm.hotelOffer)?_c('OfferLine',{attrs:{"type":"accommodation","item":_vm.hotelOffer}}):_c('HotelSkeleton',{attrs:{"type":"accommodation"}}),_c('OfferLine',{attrs:{"location":_vm.location,"type":"activity","item":_vm.activityOffer}}),_c('OfferLine',{attrs:{"location":_vm.location,"type":"restaurant","item":_vm.restaurantOffer}}),_c('div',{staticClass:"price-container tw-flex tw-flex-center tw-justify-between"},[_vm._m(1),_c('div',{staticClass:"total-holder"},[_c('span',{staticClass:"apprx"},[_vm._v("Apprx.")]),_c('span',{staticClass:"total"},[_vm._v("Total")]),(_vm.flight)?_c('span',{staticClass:"price"},[_vm._v("€"+_vm._s(_vm.totalPrice.toFixed(2)))]):_vm._e()])])],1),_c('div',{staticClass:"tw-my-10 tw-flex tw-justify-center"},[_c('v-btn',{staticClass:"button cta",attrs:{"color":"primary"},on:{"click":_vm.handleClickCustomize}},[_c('SearchIcon',{staticClass:"tw-w-4 tw-h-4 tw-mr-2"}),_vm._v(" Customize trip! ")],1)],1)]),_c('div',{staticClass:"right-column"},[_c('div',{staticClass:"tw-flex tw-flex-col storycontainer"},[_c('ExpandStoryCarousel',{attrs:{"enabled":_vm.expandStoryCarouselEnabled},on:{"toggle-expand":() => {
                _vm.expandStoryCarouselEnabled = !_vm.expandStoryCarouselEnabled;
              },"next-story-slide":() => {
                _vm.story().next();
              },"prev-story-slide":() => {
                _vm.story().prev();
              }}},[_c('div',{},[_c('InstaStory',{key:_vm.storyKey,ref:"story",staticClass:"story",style:([
                  !_vm.expandStoryCarouselEnabled
                    ? { filter: 'drop-shadow(0px 30px 30px gray)' }
                    : '',
                ]),attrs:{"showButtons":!_vm.expandStoryCarouselEnabled,"items":_vm.storyItems,"storyId":_vm.selectedExperience.exp_id,"influencer":_vm.selectedExperience.influencer_data,"active":true,"expandStoryCarouselEnabled":_vm.expandStoryCarouselEnabled},on:{"toggle-expand":() => {
                    _vm.expandStoryCarouselEnabled = !_vm.expandStoryCarouselEnabled;
                  }}})],1)])],1)]),_c('div',{staticClass:"chevron chevron-right",on:{"click":_vm.handleClickRight}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-holder left-column tw-mt-20 container mx-auto"},[_c('div',{staticClass:"sub-header"},[_vm._v("Can't decide what to book?")]),_c('div',{staticClass:"main-header"},[_c('div',[_vm._v("Buy A Curated")]),_c('div',[_vm._v("Package "),_c('span',{staticStyle:{"color":"#d62a53"}},[_vm._v("Quickly")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-start"},[_c('span',{staticClass:"disclaimer tw-mr-auto"},[_c('div',[_vm._v("Disclaimer:")]),_c('div',[_vm._v("Prices are subject to change with dates.")])])])
}]

export { render, staticRenderFns }