<template>
    <div class="tw-items-center tw-justify-between tw-mx-auto tw-mb-44 partnership">
        <div id="partnership" class="tw-mb-24">
        In Partnership With
        </div>
        <div class="tw-flex tw-flex-row tw-flex-nowrap tw-justify-center">
            <div><KiwiIcon class="kiwiIcon"/></div>
            <div><HotelbedsIcon class="hotelbedsIcon "/></div>
            <div><GetYourGuideIcon class="getYourGuideIcon"/></div>
            <div><OpenTableIcon class="kiwiIcon "/></div>
        </div>
    </div>
</template>

<script>
import KiwiIcon from '@/assets/inspirationPage/tabicons/kiwi.svg';
import HotelbedsIcon from '@/assets/landingPage/hotelbeds.svg';
import GetYourGuideIcon from '@/assets/landingPage/getyourguide.svg';
import OpenTableIcon from '@/assets/landingPage/opentable.svg';
export default {
  components: {
    KiwiIcon,
    HotelbedsIcon,
    GetYourGuideIcon,
    OpenTableIcon,
  },
};
</script>

<style lang="scss" scoped>
.partnership {
  margin-top: 8%;
}
#partnership {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 3%;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2.865vw;
  color: #747378;
}

.kiwiIcon {
  width: 20vw;
  height: 13vh;
}

.hotelbedsIcon {
  margin-left: 3rem;
  margin-top: 2rem;
  width: 19vw;
}

.getYourGuideIcon {
  margin-left: 3rem;
  width: 13vw;
  height: 13vh;
}

</style>
