<template>
  <div class="tw-p-3">
    <div class="tw-font-poppins tw-font-semibold tw-flex">
      <div class="section-title">{{ title }}</div>
      <div class="tw-inline-flex circle-container">
        <svg
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.5" cx="2" cy="2" r="2" fill="#747378" />
        </svg>
      </div>
      <div class="departure-date">
        {{ departureDate }}
      </div>
    </div>
    <div class="tw-flex tw-my-3">
      <div class="tw-flex tw-flex-col tw-items-center">
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
        <div class="tw-flex-grow tw-relative tw-flex">
          <div class="flight-arrow tw-flex tw-items-center"><PlaneIcon /></div>
          <div class="path-line"></div>
        </div>
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
      </div>
      <div class="tw-py-1 tw-pl-4">
        <div class="tw-flex tw-flex-wrap font-14-px">
          <div class="tw-font-montserrat-semi-bold tw-mr-2 tw-mb-2">
            {{ departureTime }} {{ cityFrom }}
          </div>
          <div class="tw-text-grey_medium">{{ airportFrom }}</div>
        </div>
        <div class="tw-flex tw-items-center tw-my-4 flex-wrap">
          <div class="tw-flex tw-items-center">
            <div class="chip-primary">
              {{ flyDuration }}
            </div>
            <div class="tw-flex">
              <template v-for="(airline, index) in departureAirlines">
                <img
                  :key="`airline_${index}`"
                  :src="airline"
                  class="
                    airline-img
                    mr-2
                    tw-rounded-full tw-border
                    tw-border-grey_light
                  "
                />
              </template>
            </div>
          </div>
          <div
            class="
              tw-rounded-full tw-w-max tw-px-5 tw-py-1 tw-text-sm tw-my-3
              mr-4
              tw-font-bold
              chip-grey
            "
          >
            {{
              departureAirlines.length === 0 || departureAirlines.length === 1
                ? 'Direct'
                : departureAirlines.length + ' stops'
            }}
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap font-14-px">
          <div class="tw-font-montserrat-semi-bold tw-mr-2 tw-mb-2">
            {{ arrivalTime }} {{ cityTo }}
          </div>
          <div class="tw-text-grey_medium">{{ airportTo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlaneIcon from '@/assets/bookingPage/flights/plane.svg';

export default {
  components: {
    PlaneIcon,
  },
  props: {
    title: String,
    departureTime: String,
    departureDate: String,
    arrivalTime: String,
    arrivalDate: String,
    cityFrom: String,
    airportFrom: String,
    airportFromCode: String,
    cityTo: String,
    airportTo: String,
    airportToCode: String,
    cityCodeTo: String,
    flyDuration: String,
    departureAirlines: Array,
  },
};
</script>

<style lang="scss" scoped>
.path-line {
  content: '';
  position: absolute;
  left: calc(50% - 1px);
  width: 1px;
  border-right: 2px solid #ffe4ec;
  top: -7px;
  bottom: -7px;
}

.flight-arrow {
  width: 12px;
  z-index: 1;
  &::before {
    background: white;
  }
  svg {
    background: #fff;
    padding: 7px 0;
  }
}

.airline-img {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.section-title {
  font-family: PoppinsMedium;
  font-style: normal;
  display: inline-block !important;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Primary */

  color: #d62a53;
}

.departure-date {
  display: inline-block !important;
  font-family: PoppinsMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Paragraph */

  color: #747378;
}

.circle-container {
  width: 20px;
  height: 18px;
  svg {
    margin: auto;
  }
}

.chip-primary {
  background: #ffe4ec;
  color: #d62a53;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  height: 24px;
  width: auto;
  padding: 4px 12px;

  margin-right: 8px;
}

.font-14-px {
  font-size: 14px;
}

.chip-grey {
  color: #414145;
  background: #ececec;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  padding-bottom: 6px;
  padding-top: 6px;
}

.flex-wrap {
  flex-wrap: wrap;
}
</style>
