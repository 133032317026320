<template>
  <div class="cart-flight-item tw-flex tw-flex-col tw-py-5">
    <div class="tw-flex">
      <div class="flight-row">
        <div class="tw-text-left tw-relative flight-column">
          <flight-direction
            title="DEPARTURE"
            :departureTime="departureTime"
            :departureDate="departureDate"
            :arrivalTime="arrivalTime"
            :arrivalDate="arrivalDate"
            :cityFrom="city_from"
            :airportFrom="airport_from"
            :airportFromCode="airport_from_code"
            :cityTo="city_to"
            :airportTo="airport_to"
            :airportToCode="airport_to_code"
            :flyDuration="durationFromSec(duration.departure)"
            :departureAirlines="departureAirlines"
          />
          <div class="tw-absolute tw-right-0">
            <DatePicker
              v-model="departure"
              :min="''"
              :rules="[dateFromRules.compareDates(arrival, departure)]"
            />
          </div>
        </div>

        <div
          class="tw-text-left tw-relative flight-column"
          v-if="nights_in_dest !== null"
        >
          <flight-direction
            title="RETURN"
            :departureTime="returnDepartureTime"
            :departureDate="returnDepartureDate"
            :arrivalTime="returnArrivalTime"
            :arrivalDate="returnArrivalDate"
            :cityFrom="returnCityFrom"
            :airportFrom="returnAirportFrom"
            :airportFromCode="returnAirportFromCode"
            :cityTo="returnCityTo"
            :airportTo="returnAirportTo"
            :airportToCode="returnAirportToCode"
            :flyDuration="durationFromSec(duration.return)"
            :departureAirlines="returnAirlines"
          />
          <div class="tw-absolute tw-right-0">
            <DatePicker
              v-model="arrival"
              :min="departure"
              :rules="dateCheckOutRules"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between tw-w-full">
      <div class="tw-flex-grow tw-flex">
        <div class="chip-grey tw-mr-auto">
          {{ availability.seats }} seats available
        </div>
      </div>

      <div class="tw-flex tw-items-center">
        <h4 class="price"><span>From</span>{{ racePrice }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { airlineSrc } from '@/store/flights/flightsUtils';
import DatePicker from './DatePicker.vue';
import moment from 'moment';
import FlightDirection from './FlightDirection.vue';
import { APP_CURRENCY } from '@/assets/constants/appConfig';

export default {
  components: { FlightDirection, DatePicker },
  props: {
    departure_at: String,
    arrival_at: String,
    city_from: String,
    airport_from: String,
    airport_from_code: String,
    city_to: String,
    city_code_to: String,
    airport_to: String,
    airport_to_code: String,
    itinerary: Array,
    duration: Object,
    availability: Object,
    nights_in_dest: Number,
    price: Array,
  },
  data: () => ({
    formValid: false,
    APP_CURRENCY,
    dateCheckOutRules: [ v => !!v || 'Check-out date is required' ],
    dateFromRules: {
      compareDates(checkInDate, checkOutDate) {
        this.isValid = new Date(checkInDate) <= new Date(checkOutDate);
        return (
          new Date(checkInDate) <= new Date(checkOutDate) ||
          'Date check-in must be less than date check-out'
        );
      },
    },
  }),
  computed: {
    arrival: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('setArrival', value);
      },
    },
    departure: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('setDeparture', value);
      },
    },
    departureDate() {
      return this.getDate(this.departure_at);
    },
    departureTime() {
      return this.getTime(this.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.arrival_at);
    },
    arrivalDate() {
      return this.getDate(this.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureDate() {
      return this.getDate(this.returnRoute?.departure_at);
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute?.departure_at);
    },
    returnArrivalDate() {
      return this.getDate(this.returnRouteArrival?.arrival_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnAirportFrom() {
      return this.returnRoute?.airport_from;
    },
    returnAirportFromCode() {
      return this.returnRoute?.airport_from_code;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirportTo() {
      return this.returnRouteArrival?.airport_to;
    },
    returnAirportToCode() {
      return this.returnRouteArrival?.airport_to_code;
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    seats() {
      return this.availability.seats;
    },
    racePrice() {
      return this.price.length && `€${this.price[0].price}`;
    },
    currency() {
      return this.price.length && this.price[0].currency;
    },
  },
  methods: {
    airlineImg(code) {
      return airlineSrc(code);
    },
    getDate(strDate) {
      return moment(new Date(strDate)).format('ddd MMM D');
    },
    getTime(strDate) {
      return moment(new Date(strDate)).format('h:mm A');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      // var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? 'd ' : 'd ') : '';
      var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
      var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
      // var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
      return dDisplay + hDisplay + mDisplay;
    },
  },
};
</script>
<style scoped lang="scss">
.cart-flight-item {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  border-radius: 1rem;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  background: #ffffff;
  border-radius: 16px;
  padding-left: 0rem;
  padding-top: 0.25rem;
  padding-bottom: 0px;
}

.chip-grey {
  background: #7473781a;
  color: #747378;
  margin-left: 12px !important;
}

.chip-primary {
  background: #ffe4ec;
  color: #d62a53;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  height: 24px;
  padding: 0 12px;
  margin-left: 0;
  margin-top: 2px;
}

.remove-btn {
  background: #ffe4ec;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  svg {
    margin: auto;
  }
}

.flight-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

.flight-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
}

.price {
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  color: #28262e;
  span {
    font-family: MontserratMedium;
    margin-right: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #747378;
  }
}
.experience-type-icon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: #7473781a;
  margin-right: 16px;
  display: flex;
  margin-top: 11px;

  svg {
    margin: auto;
  }
}
</style>
