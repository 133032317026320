<template>
  <div
    class="
      tw-items-center
      tw-justify-between
      tw-mx-auto
      tw-font-poppins
      tw-mb-32
      tw-ml-2
      newTothesite
    "
  >
    <div class="left-column">
      <div class="sub-header">New to the site?</div>
      <div class="main-header">
        <div>Make Your Travel</div>
        <div>Possible In <span style="color: #d62a53">Easy Steps</span></div>
      </div>
    </div>
    <div class="carousel-container tw-flex">
      <div class="chevron chevron-left" @click.prevent="previous">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <carousel 
      ref="carousel" 
      class="carousel" 
      v-bind:autoplay="true" 
      v-bind:loop="true" 
      :perPage='1' 
      :speed='200' 
      :navigateTo="0"
      :autoplayTimeout="3000" >
        <slide v-for="(item, index) in tabs" class="slide" :key="index">
          <div class="tw-flex tw-flex-row tw-justify-center">
          <div class="tw-flex semititle">{{ item.tag }}&nbsp;</div>
          <div class="titleheader tw-flex ">{{ item.title }}</div>
          </div>
          <img class="item-image" :src="item.source" alt="" />
        </slide>
      </carousel>
      <div class="chevron chevron-right tw-right-0" @click.prevent="next">
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'NewToTheSite',
  data() {
    return {
      tabs: [
        {
          tag: 'Step 1: ',
          title: 'Watch Travel Stories',
          source: require('../../../assets/landingPage/landing_car_1.png'),
        },
        {
          tag: 'Step 2: ',
          title: 'Plan and Book your trip from them',
          source: require('../../../assets/landingPage/landing_car_3.png'),
        },
        {
          tag: 'Step 3: ',
          title: 'Enjoy your next Adventure',
          source: require('../../../assets/landingPage/landing_car_2.png'),
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    next() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    previous() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/grid.scss";
@import "~@/assets/styles/app.scss";
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/skeleton.scss";

.titleheader {
  font-family: "MontserratRegular" !important;
  font-size: 2.526vw !important;
  vertical-align: top;
  letter-spacing: -0.03rem;
  margin-top: 6%;
  margin-bottom: 1.5%;
}

.semititle {
  font-family: "MontserratRegular" !important;
  font-weight: 900 !important;
  font-size: 2.526vw !important;
  vertical-align: top;
  letter-spacing: -0.03rem;
  margin-top: 6%;
  //margin-bottom: 1.5%;
}

.left-column {
  margin-left: 10%;
}

.newTothesite {
  margin-top: 8%;
}

.sub-header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2.865vw;
  margin-bottom: 0.25rem;
  color: #747378;
}

.main-header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 4.625vw;
  line-height: 94%;
  color: #28262e;
  margin-top: 0.75rem;
}

.chevron {
  //flex: 0 0 5.3%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.chevron > i {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background: #f0f0f1;
  border-radius: 1.5rem;
}

.mdi-chevron-left {
  color: black;
}
.mdi-chevron-right {
  color: black;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 40%;
}

.slide {
  flex: 0 0 100%;
  justify-content: center;
  transition: all 0.3s;
}

.item-image {
  width: 58%;
  height: 70%;
  margin-left: 22%;
}
</style>
