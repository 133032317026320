<template>
  <div class="outer tw-flex tw-items-center tw-border-b tw-p-4 tw-mt-6">
    <div
      v-bind:style="
        type === 'flight' ? { marginTop: '16px', marginBottom: 'auto' } : {}
      "
      class="icon-container tw-flex tw-rounded-full tw-items-center tw-justify-center"
    >
      <AccommodationIcon v-if="type === 'accommodation'" />
    </div>

    <div class="tw-ml-6 tw-items-center tw-w-full">
      <slot>
            <div class="tw-text-left flight-column chip-grey tw-mt-16">
            Getting the best hotel prices for you from...
        </div>
        <div class="tw-flex tw-flex-row tw-justify-center">
            <HotelbedIcon class="tw-w-24 t-h-16 tw-mt-4 tw-mb-6"/>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import HotelbedIcon from '@/assets/landingPage/hotelbeds.svg';
export default {
  name: 'OfferLine',
  components: {
    AccommodationIcon,
    HotelbedIcon,
  },
  props: {
    type: String
  }
};
</script>

<style lang="scss" scoped>


.chip-grey {
  color: 787373;
  margin-left: 12px !important;
  font-size: 0.9rem;
  color: #787373;
}

.chip-primary {
  background: #ffe4ec;
  color: #ffe4ec;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  //height: 24px;
  padding: 0 12px;
  margin-left: 0;
  margin-top: 2px;
}

.flight-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
}

.icon-container {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: #ffe4ec;
  color: #d62a53;
}


</style>
